<template>
  <div></div>
</template>

<script>
import http from "@/http"
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  async created () {
    try {
      
      const message = await http.get("Message", { params: { id: this.id }})

      const childRoute = message.type === "Message" ? "Messages" :
                         message.type === "Article" ? "Articles" :
                         message.tyoe === "Guide" ? "Guides" : ""

      if (childRoute) {
        this.$router.replace({
          name: `messages.${childRoute}`,
          params: {
            id: this.id
          }
        })
      }

    } catch {
      this.$notifyError("Fant ikke siden.")
    }
  }
}
</script>